/*-----  SKILLS.JS BELOW  -----*/

.skills {
    overflow: hidden;
    position: absolute;
    top: 0vh;
    left: 7vmin;
    width: calc(100vw - 7vmin);
    height: 100vh;
    background: #121212;
    z-index: 1;
}

.skills-content {
    position: absolute;
    top: calc(46vh - 17.5vmin);
    left: calc(17.5vw - 17.5vmin);
    width: 72vmin;
    height: 35vmin;
    font-size: 1.9vmin;
    color: #ffffff;
    opacity: 0;
    animation: opacity-delay;
    animation-delay: 2.2s;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

.skills-content h1 {
    font-family: "HelveticaNeueMedium";
    color: cyan;
    font-size: 6.3vmin;
    margin: 0.9vmin 0;
}

.iphone {
    position: absolute;
    right: calc(50vw - 174vmin);
    top: calc(50vh - 29vmin);
    height: 58vmin;
}

.iphone-svg svg {
    position: relative;
    height: 58vmin;
    z-index: 0;
}

.iphone-screen-container {
    position: relative;
    top: -100.5%; /* otherwise the screen is out of frame */
    left: 0%;
    height: 100%;
    width: 100%;
}

.iphone-section-stocks, .iphone-section-weather {
    position: relative;
    top: 0.8%;
    height: 96.3%;
    left: 16.5%;
    width: 68%;
    border-radius: 10%;
    z-index: -1;
}

@keyframes gradient-anim { 
    0%   { background-position: 50% 0% }
    50%  { background-position: 50% 100% }
    100% { background-position: 50% 0% }
}

.container-iphone-buttons {
    position: relative;
    top: 90%;
    height: 10%;
    left: 0.5%;
    width: 100%;
    border-radius: 0% 0% 20% 20%;
    background: transparent;
}

.iphone-buttons {
    position: relative;
    top: -10.5%;
    height: 9.5%;
    left: 17.5%;
    width: 66%;
    border-radius: 0% 0% 20% 20%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    animation: opacity-delay;
    animation-duration: 0.9s;
    animation-delay: 3.5s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    opacity: 0;
}

.iphone-buttons i {
    font-size: 3.7vmin;
    cursor: pointer;
    color: #101010;
}

.iphone-buttons i:hover {
    cursor: pointer;
    color: white;
}

.section-content {
    position: relative;
    top: -100%;
    height: 82%;
    left: 17%;
    width: 67%;
    font-size: 2vmin;
    animation: opacity-delay;
    animation-duration: 0.9s;
    animation-delay: 3.8s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    opacity: 0;
}

.content-stock, .content-weather {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 4%;
    display: grid;
    grid-template-rows: 3vmin 7vmin 7vmin 7vmin 7vmin;
    grid-template-columns: 20.5vmin;
    grid-row-gap: 3vmin;
    color: black;
}

.content-stock p, .content-weather p {
    position: relative;
    top: 0.5vmin;
    background: #101010;
    color: white;
    font-size: 1.8vmin;
    text-align: center;
    padding-top: 0.4vmin;
    border-radius: 1vmin;
}

.content-stock li, .content-weather li {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: #232323;
    border-radius: 1vmin;
    animation: opacity-delay;
    animation-duration: 0.9s;
    animation-delay: 0.3s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    opacity: 0;
}

.data-text {
    color: white;
}

.data-text.stocks {
    position: relative;
    left: -1vmin;
}

.data-text.weathers {
    position: relative;
	top: -0.1vmin;
}

.content-weather > li:nth-child(5) > .data-text.weathers { 
	position: relative;
	left: 0.5vmin;
}

.stock {
    font-size: 1.66vmin;
}

.stock.loading {
    position: relative;
    left: -2vmin;
}

.stock-price, .stock-percent {
    font-size: 1.48vmin;
}

.stock-img-TSLA {
    height: 5vmin;
    animation: opacity-delay;
    animation-duration: 0.9s;
    animation-fill-mode: forwards;
    opacity: 0;
}

.stock-img-AMZN {
    height: 5vmin;
    animation: opacity-delay;
    animation-duration: 0.9s;
    animation-fill-mode: forwards;
    opacity: 0;
}

.stock-img-AAPL {
    height: 5vmin;
    animation: opacity-delay;
    animation-duration: 0.9s;
    animation-fill-mode: forwards;
    opacity: 0;
}

.stock-img-AMD {
    height: 4vmin;
    animation: opacity-delay;
    animation-duration: 0.9s;
    animation-fill-mode: forwards;
    opacity: 0;
}

.icon-img-weather {
    height: 9vmin;
    animation: opacity-delay;
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    opacity: 0;
}

.icon-img-country {
    position: relative;
    top: 0.4vmin;
    height: 2vmin;
    animation: opacity-delay;
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    opacity: 0;
}
    