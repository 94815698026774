/*-----  NAVBAR.JS BELOW  -----*/

.navbar {
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 7vmin;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #000000;
    z-index: 99;
}

.navbar a { /* (.navbar a) is actually <Navlink> in JSX  */
    color: #494949;
    width: 7vmin;
    padding: 2.9vmin 0vmin;
    font-size: 2.4vmin;
    animation: opacity-delay;
    animation-delay: 2s;
    animation-duration: 0.9s;
    animation-fill-mode: forwards;
    opacity: 0; 
}

.navbar a:hover {
    color: #d3d3d3;
}

.navbar-icon {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.navbar-icon p {
    padding-top: 0.4vmin;
    font-size: 1.1vmin;
    opacity: 0;
}

.navbar a:hover .navbar-icon p {
    opacity: 1;
}

.navbar a.active .navbar-icon p {
    opacity: 1;
}

.navbar-logo {
    position: absolute;
    left: 0;
    top: 0;
    width: 7vmin;
    height: 8.5vmin;
    background: #000000;
    background-image: url(../../assets/IMG/favicon.svg);
    background-size: 62%;
    background-repeat: no-repeat;
    background-position: center 30%;
}

.navbar-logo:hover {
    background-size: 74%;
}

.navbar-logo p {
    color: #ffffff;
    position: absolute;
    top: 66%;
    left: 30%;
    font-size: 1.3vmin;
}
