.particles-js {
    overflow: hidden;
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background: #121212;
    animation: opacity-delay;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-delay: 0.9s;
    animation-duration: 0.5s;
    opacity: 0;
}