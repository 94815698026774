/*-----  TRANSITION ANIMATION BELOW  -----*/

.transition-animation {
    overflow: hidden;
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    z-index: 99;
    animation-name: animation-wrapper;
    animation-duration: 4s;
    animation-fill-mode: forwards;
}

@keyframes animation-wrapper {
    0%   {  }
    100% { display: none; z-index: -10;}
}

.loader {
    position: absolute;
    bottom: 0%;
    left: 50%;
    transform: translateX(-50%);
    height: 0.7vmin;
    width: 20%;
    overflow: hidden;
    background-color: #000000;
}

.loader-bar {
    height: 100%;
    width: 0%;
    overflow: hidden;
    background-color: cyan;
    animation: loading 0.9s ease-in-out;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
}

@keyframes loading {
    0%     { width: 0% }
    86%    { width: 100%; background-color: cyan }
    100%   { width: 100%; background-color: #000000 }
}

.anim-box1 {
    position: absolute;
    bottom: 7%;
    left: 50%;
    transform: translateX(-50%);
    border: 0.6vmin solid cyan;
    width: 5vmin;
    height: 5vmin;
}

.anim-box2 {
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translateX(-50%);
    border: 0.6vmin solid yellow;
    width: 5vmin;
    height: 5vmin;
}

.open-top {
    position: absolute;
    top: -50%;
    width: 100%;
    height: 50.08%;
    background: #000000;
    animation-name: open-top;
    animation-delay: 0s;
    animation-duration: 4s;
    animation-fill-mode: forwards;
}

.open-bottom {
    position: absolute;
    bottom: -50%;
    width: 100%;
    height: 50.08%;
    background: #000000;
    animation-name: open-bottom;
    animation-delay: 0s;
    animation-duration: 4s;
    animation-fill-mode: forwards;
}

@keyframes open-top {
    0%   { transform: translateY(0%) }
    10%  { transform: translateY(100%) }
    40%  { transform: translateY(100%) }
    100% { transform: translateY(-100%) }
}

@keyframes open-bottom {
    0%   { transform: translateY(0%) }
    10%  { transform: translateY(-100%) }
    40%  { transform: translateY(-100%) }
    100% { transform: translateY(100%) }
}
