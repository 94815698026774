/*-----  PROJECTS.JS BELOW  -----*/

.projects {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: absolute;
    top: 0vh;
    left: 7vmin;
    width: calc(100vw - 7vmin);
    height: 100vh;
    background: #121212;
    z-index: 1;
}

.projects-content {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 80%;
    z-index: 1;
    opacity: 0;
    animation: opacity-delay;
    animation-delay: 2.2s;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    margin-bottom: 10vmin;
}

.projects-content h1 {
    font-family: "HelveticaNeueMedium";
    color: cyan;
    font-size: 6.3vmin;
    margin: 2vmin 0;
}

.project-list {
    color: white;
    border: 0.3vmin solid cyan;
}

.project {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: white;
    width: 140vmin;
    height: 70vmin;
}

.project .description {
    width: 40%;
    font-size: 2.2vmin;
}

.project .title {
    font-family: 'HelveticaNeueMedium';
    font-weight: bolder;
    color: cyan;
    font-size: 5vmin;
    cursor: pointer;
}

.project .title:hover {
    text-decoration: underline;
}

.project .video-container {
    margin-left: 4vmin;
    width: 56%;
}

.project .video-playing {
    height: 100%;
    width: 100%;
}