/*-----  CONTACT.JS BELOW  -----*/

.contact {
    overflow: hidden;
    position: absolute;
    top: 0vh;
    left: 7vmin;
    width: calc(100vw - 7vmin);
    height: 100vh;
    background: #121212;
    z-index: 1;
}

.contact-form {
    position: absolute;
    top: calc(47vh - 17.5vmin);
    left: calc(22.5vw - 20vmin);
    display: grid;
    grid-template-columns: 40vmin;
    grid-template-rows: 5vmin 5vmin 5vmin 15vmin 5vmin;
    grid-gap: 1.8vmin;
    opacity: 0;
    animation: opacity-delay;
    animation-delay: 2s;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

.contact-form h1 {
    font-family: "HelveticaNeueMedium";
    color: cyan;
    font-size: 6.3vmin;
    position: relative;
    top: -3vmin;
}

.contact-form input {
    font-size: 2.2vmin;
    border: none;
    padding-left: 2vmin;
    padding-right: 2vmin;
    background: #232323;
    color: #ffffff;
}

.contact-form input:focus {
    border-left: 3px solid cyan;
}

.contact-form button {
    font-size: 2.2vmin;
    width: 10vmin;
    border: 0.2vmin solid cyan;
    background: transparent;
    color: cyan;
    border-radius: 0.9vmin;
    position: relative;
    top: 1.5vmin;
}

.contact-form button:hover {
    color: #171717;
    background: cyan;
}

.contact-form textarea {
    font-size: 2.2vmin;
    border: none;
    padding-left: 2vmin;
    padding-right: 2vmin;
    background: #232323;
    color: #ffffff;
}

.contact-form textarea:focus {
    border-left: 3px solid cyan;
}

.google-map {
    position: absolute;
    top: 0vh;
    right: 0vw;
    width: 49.8vw;
    height: 100vh;
    opacity: 0;
    animation: opacity-delay;
    animation-delay: 2.5s;
    animation-duration: 0.9s;
    animation-fill-mode: forwards;
    z-index: 100; /* navbar is set at index 99 but if you fullscreen the google map it needs to overlap it! */
}

.contact-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 2.5vmin;
    padding: 0 2vmin;
    color: grey;
    background-color: #090909;
    width: 42vmin;
    border-left: 2px solid cyan;
}

.contact-text i {
    justify-self: flex-end;
}

.contact-text a {
	color: darkgrey;
}

.colored {
	color: cyan
}

.copy-icon:hover {
	color: cyan;
	cursor: pointer;
}
