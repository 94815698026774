.marker {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: -1.1vmin;
    left: -1.1vmin;
    width: 2.2vmin;
    height: 2.2vmin;
    background: transparent;
    border: 0.5vmin solid yellow;
	animation: opacity-delay;
    animation-duration: 1s;
    animation-delay: 3s;
    animation-fill-mode: forwards;
    opacity: 0;
}

.marker p {
    font-size: 1.7vmin;
    padding: 0.15vmin;
    position: relative;
    left: 2vmin;
    background-color: #101010;
    color: white;
    width: fit-content;
    text-wrap: nowrap;
    text-align: center;
}
