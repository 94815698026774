@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css?family=Righteous');
@import url('https://fonts.googleapis.com/css?family=Caveat');
@font-face {
    font-family: "HelveticaNeueMedium";
    src: url('./assets/fonts/HelveticaNeueMedium.woff') format('woff');
}
* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    outline: none;
}
html {
    height: 100%;
    scroll-behavior: smooth;
}
body {
    height: 100%;
    background-color: #121212;
    overflow-x: hidden;
    overflow-y: hidden;
}
@-moz-document url-prefix() {
    html, body { overflow-x: hidden !important; overflow-y: hidden !important; scrollbar-width: none; }
}
::-webkit-scrollbar {
    display: none; 
}
li {
    list-style: none;
}
a {
    color: cyan;
}
button {
    padding: 1vmin;
    background-color: #ffffff;
    border: 2px solid #808080;
    color: #808080;
    cursor: pointer;
}
input {
    padding: 1vmin;
}
::placeholder {
    color: #808080;
}
input:focus::-webkit-input-placeholder {
    color: transparent;
}
input:focus:-moz-placeholder {
    color: transparent;
}
input:focus::-moz-placeholder {
    color: transparent;
}
input:focus:-ms-input-placeholder {
    color: transparent;
}
textarea {
    padding: 1vmin;
    overflow: hidden;
}
textarea:focus::-webkit-input-placeholder {
    color: transparent;
}
textarea:focus:-moz-placeholder {
    color: transparent;
}
textarea:focus::-moz-placeholder {
    color: transparent;
}
textarea:focus:-ms-input-placeholder {
    color: transparent;
}
select {
    padding: 1vmin;
}
@keyframes opacity-delay {
    0%   { opacity: 0 }
    100% { opacity: 1 }
}

/*-----  PAGENOTFOUND BELOW -----*/

.pagenotfound {
    overflow: hidden;
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background: #121212;
    z-index: 1;
}

.pagenotfound h1 {
    position: absolute;
    top: calc(47% - 2.5vmax);
    left: calc(50% - 22vmax);
    font-size: 5vmax;
    color: #0f0f0f;
}

/*-----  MEDIA QUERIES BELOW  -----*/

/* Tablets */
@media only screen and (max-width: 1152px) {
    body {
        display: none;
    }
    html::after {
        font-size: 5vmin;
        color: grey;
        content: "Mobile version not available \A (maximize your window if not mobile).";
        white-space: pre;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100vw;
        height: 100vh;
    }
    html {
        background: #101010;
    }
}

/* Internet Explorer */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    body {
        display: none;
    }
    html::after {
        font-size: 5vmin;
        color: grey;
        content: "Internet explorer not supported.";
        vertical-align: -44vh;
        margin-left: 20vw;
    }
    html {
        background: #101010;
    }
}

/* Safari */
_::-webkit-full-page-media, _:future, :root .particles-js {
    display: none;
}