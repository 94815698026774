/*-----  HOME.JS BELOW  -----*/

.home {
    overflow: hidden;
    position: absolute;
    top: 0vh;
    left: 7vmin;
    width: calc(100vw - 7vmin);
    height: 100vh;
    background: #121212;
    z-index: 1;
}

.particles-cover {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.background-svg {
    position: absolute;
    top: 0vh;
    left: 0vw;
    width: calc(100vw - 7vmin);
    height: 100vh;
    background: url(../../assets/IMG/background.svg);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    animation: opacity-delay;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-delay: 0.6s;
    animation-duration: 0.3s;
    opacity: 0;
    z-index: 1;
}

.logo-box {
    position: absolute;
    left: calc(34.4vw - 4.5vmax);
    top: calc(49vh - 4.5vmax);
    background: cyan;
    box-shadow: 0px 2px 20px 0px rgba(0, 255, 255, 0.4);
    width: 9vmax;
    height: 9vmax;
    z-index: 1;
    opacity: 0;
}

.logo-box p {
    position: absolute;
    top: calc(9.9vmax - 0.7vmax);
    left: calc(0.2vmax - 0.7vmax);
    font-size: 1.4vmax;
    font-family: 'Caveat';
    color: cyan;
    opacity: 0;
}

.logo-box2 {
    position: absolute;
    left: calc(34.4vw - 4.5vmax);
    top: calc(49vh - 4.5vmax);
    background: yellow;
    /* box-shadow: 0px 2px 20px 0px rgba(255, 255, 0, 0.4); */
    width: 9vmax;
    height: 9vmax;
    z-index: 1;
    opacity: 0;
}

.logo-box2 p {
    position: absolute;
    top: calc(-1.1vmax - 0.7vmax);
    left: calc(5.8vmax - 0.7vmax);
    font-size: 1.4vmax;
    font-family: 'Caveat';
    color: yellow;
    opacity: 0;
}

.code-icon {
    position: absolute;
    top: calc(4.6vmax - 4vmax);
    left: calc(4.5vmax - 4vmax);
    font-size: 8vmax;
    color: #232323;
    z-index: 1;
    cursor: default;
}

.names {
    position: absolute;
    top: calc(49.8vh - 3.2vmax);
    left: -50vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    opacity: 1;
    z-index: 1;
}

.first-name, .last-name {
    margin-top: -0.4vmax;
    margin-bottom: -0.4vmax;
}

.first-name p {
    font-size: 3.2vmax;
    font-family: 'Righteous', cursive;
    color: cyan;
    cursor: default;
}

.last-name p {
    font-size: 3.2vmax;
    font-family: 'Righteous', cursive;
    color: yellow;
    cursor: default;
}

.call-to-action {
    position: absolute;
    top: calc(54.7vh + 3.4vmax);
    left: 2.2vw;
    font-size: 1.8vmin;
    color: yellow;
    padding: 0.6vmax 2.2vmax 0.6vmax 2.2vmax;
    border: 0.3vmin solid cyan;
    border-radius: 0.9vmin;
    box-shadow: 0px 2px 20px 0px rgba(0, 255, 255, 0.1);
    background: transparent;
    z-index: 1;
}

.call-to-action:hover {
    border: 0.3vmin solid yellow;
    box-shadow: 0px 2px 20px 0px rgba(255, 255, 0, 0.1);
}

.icons {
    position: absolute;
    top: calc(-36.7vh - 3.5vmax);
    right: calc(14.9vw - 3.5vmax);
    display: flex;
    justify-content: space-around;
    align-items: center;
    opacity: 1;
    z-index: 1;
}

.icons i {
    font-size: 6.9vmax;
    margin-left: 3.5vmin;
}

.typescript {
    height: 6vmax;
    margin-right: 1.2vmin;
}


.csharp {
    height: 8vmax;
    margin-right: 1.2vmin;
}

.net {
    margin-top: 1.2vmin;
    height: 6.6vmax;
}

.css {
    color: cyan;
}

.javascript {
    margin-right: -1vmin;
    color: yellow;
}

.react {
    color: cyan;
}