@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Righteous);
@import url(https://fonts.googleapis.com/css?family=Caveat);
@font-face {
    font-family: "HelveticaNeueMedium";
    src: url(/static/media/HelveticaNeueMedium.ab1a472f.woff) format('woff');
}
* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    outline: none;
}
html {
    height: 100%;
    scroll-behavior: smooth;
}
body {
    height: 100%;
    background-color: #121212;
    overflow-x: hidden;
    overflow-y: hidden;
}
@-moz-document url-prefix() {
    html, body { overflow-x: hidden !important; overflow-y: hidden !important; scrollbar-width: none; }
}
::-webkit-scrollbar {
    display: none; 
}
li {
    list-style: none;
}
a {
    color: cyan;
}
button {
    padding: 1vmin;
    background-color: #ffffff;
    border: 2px solid #808080;
    color: #808080;
    cursor: pointer;
}
input {
    padding: 1vmin;
}
::-webkit-input-placeholder {
    color: #808080;
}
:-ms-input-placeholder {
    color: #808080;
}
::-ms-input-placeholder {
    color: #808080;
}
::placeholder {
    color: #808080;
}
input:focus::-webkit-input-placeholder {
    color: transparent;
}
input:focus:-moz-placeholder {
    color: transparent;
}
input:focus::-moz-placeholder {
    color: transparent;
}
input:focus:-ms-input-placeholder {
    color: transparent;
}
textarea {
    padding: 1vmin;
    overflow: hidden;
}
textarea:focus::-webkit-input-placeholder {
    color: transparent;
}
textarea:focus:-moz-placeholder {
    color: transparent;
}
textarea:focus::-moz-placeholder {
    color: transparent;
}
textarea:focus:-ms-input-placeholder {
    color: transparent;
}
select {
    padding: 1vmin;
}
@-webkit-keyframes opacity-delay {
    0%   { opacity: 0 }
    100% { opacity: 1 }
}
@keyframes opacity-delay {
    0%   { opacity: 0 }
    100% { opacity: 1 }
}

/*-----  PAGENOTFOUND BELOW -----*/

.pagenotfound {
    overflow: hidden;
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background: #121212;
    z-index: 1;
}

.pagenotfound h1 {
    position: absolute;
    top: calc(47% - 2.5vmax);
    left: calc(50% - 22vmax);
    font-size: 5vmax;
    color: #0f0f0f;
}

/*-----  MEDIA QUERIES BELOW  -----*/

/* Tablets */
@media only screen and (max-width: 1152px) {
    body {
        display: none;
    }
    html::after {
        font-size: 5vmin;
        color: grey;
        content: "Mobile version not available \A (maximize your window if not mobile).";
        white-space: pre;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100vw;
        height: 100vh;
    }
    html {
        background: #101010;
    }
}

/* Internet Explorer */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    body {
        display: none;
    }
    html::after {
        font-size: 5vmin;
        color: grey;
        content: "Internet explorer not supported.";
        vertical-align: -44vh;
        margin-left: 20vw;
    }
    html {
        background: #101010;
    }
}

/* Safari */
_::-webkit-full-page-media, _:future, :root .particles-js {
    display: none;
}
/*-----  NAVBAR.JS BELOW  -----*/

.navbar {
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 7vmin;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #000000;
    z-index: 99;
}

.navbar a { /* (.navbar a) is actually <Navlink> in JSX  */
    color: #494949;
    width: 7vmin;
    padding: 2.9vmin 0vmin;
    font-size: 2.4vmin;
    -webkit-animation: opacity-delay;
            animation: opacity-delay;
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
    -webkit-animation-duration: 0.9s;
            animation-duration: 0.9s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    opacity: 0; 
}

.navbar a:hover {
    color: #d3d3d3;
}

.navbar-icon {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.navbar-icon p {
    padding-top: 0.4vmin;
    font-size: 1.1vmin;
    opacity: 0;
}

.navbar a:hover .navbar-icon p {
    opacity: 1;
}

.navbar a.active .navbar-icon p {
    opacity: 1;
}

.navbar-logo {
    position: absolute;
    left: 0;
    top: 0;
    width: 7vmin;
    height: 8.5vmin;
    background: #000000;
    background-image: url(/static/media/favicon.930b583a.svg);
    background-size: 62%;
    background-repeat: no-repeat;
    background-position: center 30%;
}

.navbar-logo:hover {
    background-size: 74%;
}

.navbar-logo p {
    color: #ffffff;
    position: absolute;
    top: 66%;
    left: 30%;
    font-size: 1.3vmin;
}

/*-----  HOME.JS BELOW  -----*/

.home {
    overflow: hidden;
    position: absolute;
    top: 0vh;
    left: 7vmin;
    width: calc(100vw - 7vmin);
    height: 100vh;
    background: #121212;
    z-index: 1;
}

.particles-cover {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.background-svg {
    position: absolute;
    top: 0vh;
    left: 0vw;
    width: calc(100vw - 7vmin);
    height: 100vh;
    background: url(/static/media/background.a3e8f4fe.svg);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-animation: opacity-delay;
            animation: opacity-delay;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
    opacity: 0;
    z-index: 1;
}

.logo-box {
    position: absolute;
    left: calc(34.4vw - 4.5vmax);
    top: calc(49vh - 4.5vmax);
    background: cyan;
    box-shadow: 0px 2px 20px 0px rgba(0, 255, 255, 0.4);
    width: 9vmax;
    height: 9vmax;
    z-index: 1;
    opacity: 0;
}

.logo-box p {
    position: absolute;
    top: calc(9.9vmax - 0.7vmax);
    left: calc(0.2vmax - 0.7vmax);
    font-size: 1.4vmax;
    font-family: 'Caveat';
    color: cyan;
    opacity: 0;
}

.logo-box2 {
    position: absolute;
    left: calc(34.4vw - 4.5vmax);
    top: calc(49vh - 4.5vmax);
    background: yellow;
    /* box-shadow: 0px 2px 20px 0px rgba(255, 255, 0, 0.4); */
    width: 9vmax;
    height: 9vmax;
    z-index: 1;
    opacity: 0;
}

.logo-box2 p {
    position: absolute;
    top: calc(-1.1vmax - 0.7vmax);
    left: calc(5.8vmax - 0.7vmax);
    font-size: 1.4vmax;
    font-family: 'Caveat';
    color: yellow;
    opacity: 0;
}

.code-icon {
    position: absolute;
    top: calc(4.6vmax - 4vmax);
    left: calc(4.5vmax - 4vmax);
    font-size: 8vmax;
    color: #232323;
    z-index: 1;
    cursor: default;
}

.names {
    position: absolute;
    top: calc(49.8vh - 3.2vmax);
    left: -50vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    opacity: 1;
    z-index: 1;
}

.first-name, .last-name {
    margin-top: -0.4vmax;
    margin-bottom: -0.4vmax;
}

.first-name p {
    font-size: 3.2vmax;
    font-family: 'Righteous', cursive;
    color: cyan;
    cursor: default;
}

.last-name p {
    font-size: 3.2vmax;
    font-family: 'Righteous', cursive;
    color: yellow;
    cursor: default;
}

.call-to-action {
    position: absolute;
    top: calc(54.7vh + 3.4vmax);
    left: 2.2vw;
    font-size: 1.8vmin;
    color: yellow;
    padding: 0.6vmax 2.2vmax 0.6vmax 2.2vmax;
    border: 0.3vmin solid cyan;
    border-radius: 0.9vmin;
    box-shadow: 0px 2px 20px 0px rgba(0, 255, 255, 0.1);
    background: transparent;
    z-index: 1;
}

.call-to-action:hover {
    border: 0.3vmin solid yellow;
    box-shadow: 0px 2px 20px 0px rgba(255, 255, 0, 0.1);
}

.icons {
    position: absolute;
    top: calc(-36.7vh - 3.5vmax);
    right: calc(14.9vw - 3.5vmax);
    display: flex;
    justify-content: space-around;
    align-items: center;
    opacity: 1;
    z-index: 1;
}

.icons i {
    font-size: 6.9vmax;
    margin-left: 3.5vmin;
}

.typescript {
    height: 6vmax;
    margin-right: 1.2vmin;
}


.csharp {
    height: 8vmax;
    margin-right: 1.2vmin;
}

.net {
    margin-top: 1.2vmin;
    height: 6.6vmax;
}

.css {
    color: cyan;
}

.javascript {
    margin-right: -1vmin;
    color: yellow;
}

.react {
    color: cyan;
}
.particles-js {
    overflow: hidden;
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background: #121212;
    -webkit-animation: opacity-delay;
            animation: opacity-delay;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-animation-delay: 0.9s;
            animation-delay: 0.9s;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    opacity: 0;
}
/*-----  SKILLS.JS BELOW  -----*/

.skills {
    overflow: hidden;
    position: absolute;
    top: 0vh;
    left: 7vmin;
    width: calc(100vw - 7vmin);
    height: 100vh;
    background: #121212;
    z-index: 1;
}

.skills-content {
    position: absolute;
    top: calc(46vh - 17.5vmin);
    left: calc(17.5vw - 17.5vmin);
    width: 72vmin;
    height: 35vmin;
    font-size: 1.9vmin;
    color: #ffffff;
    opacity: 0;
    -webkit-animation: opacity-delay;
            animation: opacity-delay;
    -webkit-animation-delay: 2.2s;
            animation-delay: 2.2s;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.skills-content h1 {
    font-family: "HelveticaNeueMedium";
    color: cyan;
    font-size: 6.3vmin;
    margin: 0.9vmin 0;
}

.iphone {
    position: absolute;
    right: calc(50vw - 174vmin);
    top: calc(50vh - 29vmin);
    height: 58vmin;
}

.iphone-svg svg {
    position: relative;
    height: 58vmin;
    z-index: 0;
}

.iphone-screen-container {
    position: relative;
    top: -100.5%; /* otherwise the screen is out of frame */
    left: 0%;
    height: 100%;
    width: 100%;
}

.iphone-section-stocks, .iphone-section-weather {
    position: relative;
    top: 0.8%;
    height: 96.3%;
    left: 16.5%;
    width: 68%;
    border-radius: 10%;
    z-index: -1;
}

@-webkit-keyframes gradient-anim { 
    0%   { background-position: 50% 0% }
    50%  { background-position: 50% 100% }
    100% { background-position: 50% 0% }
}

@keyframes gradient-anim { 
    0%   { background-position: 50% 0% }
    50%  { background-position: 50% 100% }
    100% { background-position: 50% 0% }
}

.container-iphone-buttons {
    position: relative;
    top: 90%;
    height: 10%;
    left: 0.5%;
    width: 100%;
    border-radius: 0% 0% 20% 20%;
    background: transparent;
}

.iphone-buttons {
    position: relative;
    top: -10.5%;
    height: 9.5%;
    left: 17.5%;
    width: 66%;
    border-radius: 0% 0% 20% 20%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    -webkit-animation: opacity-delay;
            animation: opacity-delay;
    -webkit-animation-duration: 0.9s;
            animation-duration: 0.9s;
    -webkit-animation-delay: 3.5s;
            animation-delay: 3.5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    opacity: 0;
}

.iphone-buttons i {
    font-size: 3.7vmin;
    cursor: pointer;
    color: #101010;
}

.iphone-buttons i:hover {
    cursor: pointer;
    color: white;
}

.section-content {
    position: relative;
    top: -100%;
    height: 82%;
    left: 17%;
    width: 67%;
    font-size: 2vmin;
    -webkit-animation: opacity-delay;
            animation: opacity-delay;
    -webkit-animation-duration: 0.9s;
            animation-duration: 0.9s;
    -webkit-animation-delay: 3.8s;
            animation-delay: 3.8s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    opacity: 0;
}

.content-stock, .content-weather {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 4%;
    display: grid;
    grid-template-rows: 3vmin 7vmin 7vmin 7vmin 7vmin;
    grid-template-columns: 20.5vmin;
    grid-row-gap: 3vmin;
    color: black;
}

.content-stock p, .content-weather p {
    position: relative;
    top: 0.5vmin;
    background: #101010;
    color: white;
    font-size: 1.8vmin;
    text-align: center;
    padding-top: 0.4vmin;
    border-radius: 1vmin;
}

.content-stock li, .content-weather li {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: #232323;
    border-radius: 1vmin;
    -webkit-animation: opacity-delay;
            animation: opacity-delay;
    -webkit-animation-duration: 0.9s;
            animation-duration: 0.9s;
    -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    opacity: 0;
}

.data-text {
    color: white;
}

.data-text.stocks {
    position: relative;
    left: -1vmin;
}

.data-text.weathers {
    position: relative;
	top: -0.1vmin;
}

.content-weather > li:nth-child(5) > .data-text.weathers { 
	position: relative;
	left: 0.5vmin;
}

.stock {
    font-size: 1.66vmin;
}

.stock.loading {
    position: relative;
    left: -2vmin;
}

.stock-price, .stock-percent {
    font-size: 1.48vmin;
}

.stock-img-TSLA {
    height: 5vmin;
    -webkit-animation: opacity-delay;
            animation: opacity-delay;
    -webkit-animation-duration: 0.9s;
            animation-duration: 0.9s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    opacity: 0;
}

.stock-img-AMZN {
    height: 5vmin;
    -webkit-animation: opacity-delay;
            animation: opacity-delay;
    -webkit-animation-duration: 0.9s;
            animation-duration: 0.9s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    opacity: 0;
}

.stock-img-AAPL {
    height: 5vmin;
    -webkit-animation: opacity-delay;
            animation: opacity-delay;
    -webkit-animation-duration: 0.9s;
            animation-duration: 0.9s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    opacity: 0;
}

.stock-img-AMD {
    height: 4vmin;
    -webkit-animation: opacity-delay;
            animation: opacity-delay;
    -webkit-animation-duration: 0.9s;
            animation-duration: 0.9s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    opacity: 0;
}

.icon-img-weather {
    height: 9vmin;
    -webkit-animation: opacity-delay;
            animation: opacity-delay;
    -webkit-animation-duration: 1.8s;
            animation-duration: 1.8s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    opacity: 0;
}

.icon-img-country {
    position: relative;
    top: 0.4vmin;
    height: 2vmin;
    -webkit-animation: opacity-delay;
            animation: opacity-delay;
    -webkit-animation-duration: 1.8s;
            animation-duration: 1.8s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    opacity: 0;
}
    
/*-----  TRANSITION ANIMATION BELOW  -----*/

.transition-animation {
    overflow: hidden;
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    z-index: 99;
    -webkit-animation-name: animation-wrapper;
            animation-name: animation-wrapper;
    -webkit-animation-duration: 4s;
            animation-duration: 4s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

@-webkit-keyframes animation-wrapper {
    0%   {  }
    100% { display: none; z-index: -10;}
}

@keyframes animation-wrapper {
    0%   {  }
    100% { display: none; z-index: -10;}
}

.loader {
    position: absolute;
    bottom: 0%;
    left: 50%;
    transform: translateX(-50%);
    height: 0.7vmin;
    width: 20%;
    overflow: hidden;
    background-color: #000000;
}

.loader-bar {
    height: 100%;
    width: 0%;
    overflow: hidden;
    background-color: cyan;
    -webkit-animation: loading 0.9s ease-in-out;
            animation: loading 0.9s ease-in-out;
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

@-webkit-keyframes loading {
    0%     { width: 0% }
    86%    { width: 100%; background-color: cyan }
    100%   { width: 100%; background-color: #000000 }
}

@keyframes loading {
    0%     { width: 0% }
    86%    { width: 100%; background-color: cyan }
    100%   { width: 100%; background-color: #000000 }
}

.anim-box1 {
    position: absolute;
    bottom: 7%;
    left: 50%;
    transform: translateX(-50%);
    border: 0.6vmin solid cyan;
    width: 5vmin;
    height: 5vmin;
}

.anim-box2 {
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translateX(-50%);
    border: 0.6vmin solid yellow;
    width: 5vmin;
    height: 5vmin;
}

.open-top {
    position: absolute;
    top: -50%;
    width: 100%;
    height: 50.08%;
    background: #000000;
    -webkit-animation-name: open-top;
            animation-name: open-top;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
    -webkit-animation-duration: 4s;
            animation-duration: 4s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.open-bottom {
    position: absolute;
    bottom: -50%;
    width: 100%;
    height: 50.08%;
    background: #000000;
    -webkit-animation-name: open-bottom;
            animation-name: open-bottom;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
    -webkit-animation-duration: 4s;
            animation-duration: 4s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

@-webkit-keyframes open-top {
    0%   { transform: translateY(0%) }
    10%  { transform: translateY(100%) }
    40%  { transform: translateY(100%) }
    100% { transform: translateY(-100%) }
}

@keyframes open-top {
    0%   { transform: translateY(0%) }
    10%  { transform: translateY(100%) }
    40%  { transform: translateY(100%) }
    100% { transform: translateY(-100%) }
}

@-webkit-keyframes open-bottom {
    0%   { transform: translateY(0%) }
    10%  { transform: translateY(-100%) }
    40%  { transform: translateY(-100%) }
    100% { transform: translateY(100%) }
}

@keyframes open-bottom {
    0%   { transform: translateY(0%) }
    10%  { transform: translateY(-100%) }
    40%  { transform: translateY(-100%) }
    100% { transform: translateY(100%) }
}

/*-----  PROJECTS.JS BELOW  -----*/

.projects {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: absolute;
    top: 0vh;
    left: 7vmin;
    width: calc(100vw - 7vmin);
    height: 100vh;
    background: #121212;
    z-index: 1;
}

.projects-content {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 80%;
    z-index: 1;
    opacity: 0;
    -webkit-animation: opacity-delay;
            animation: opacity-delay;
    -webkit-animation-delay: 2.2s;
            animation-delay: 2.2s;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    margin-bottom: 10vmin;
}

.projects-content h1 {
    font-family: "HelveticaNeueMedium";
    color: cyan;
    font-size: 6.3vmin;
    margin: 2vmin 0;
}

.project-list {
    color: white;
    border: 0.3vmin solid cyan;
}

.project {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: white;
    width: 140vmin;
    height: 70vmin;
}

.project .description {
    width: 40%;
    font-size: 2.2vmin;
}

.project .title {
    font-family: 'HelveticaNeueMedium';
    font-weight: bolder;
    color: cyan;
    font-size: 5vmin;
    cursor: pointer;
}

.project .title:hover {
    text-decoration: underline;
}

.project .video-container {
    margin-left: 4vmin;
    width: 56%;
}

.project .video-playing {
    height: 100%;
    width: 100%;
}
/*-----  CONTACT.JS BELOW  -----*/

.contact {
    overflow: hidden;
    position: absolute;
    top: 0vh;
    left: 7vmin;
    width: calc(100vw - 7vmin);
    height: 100vh;
    background: #121212;
    z-index: 1;
}

.contact-form {
    position: absolute;
    top: calc(47vh - 17.5vmin);
    left: calc(22.5vw - 20vmin);
    display: grid;
    grid-template-columns: 40vmin;
    grid-template-rows: 5vmin 5vmin 5vmin 15vmin 5vmin;
    grid-gap: 1.8vmin;
    opacity: 0;
    -webkit-animation: opacity-delay;
            animation: opacity-delay;
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.contact-form h1 {
    font-family: "HelveticaNeueMedium";
    color: cyan;
    font-size: 6.3vmin;
    position: relative;
    top: -3vmin;
}

.contact-form input {
    font-size: 2.2vmin;
    border: none;
    padding-left: 2vmin;
    padding-right: 2vmin;
    background: #232323;
    color: #ffffff;
}

.contact-form input:focus {
    border-left: 3px solid cyan;
}

.contact-form button {
    font-size: 2.2vmin;
    width: 10vmin;
    border: 0.2vmin solid cyan;
    background: transparent;
    color: cyan;
    border-radius: 0.9vmin;
    position: relative;
    top: 1.5vmin;
}

.contact-form button:hover {
    color: #171717;
    background: cyan;
}

.contact-form textarea {
    font-size: 2.2vmin;
    border: none;
    padding-left: 2vmin;
    padding-right: 2vmin;
    background: #232323;
    color: #ffffff;
}

.contact-form textarea:focus {
    border-left: 3px solid cyan;
}

.google-map {
    position: absolute;
    top: 0vh;
    right: 0vw;
    width: 49.8vw;
    height: 100vh;
    opacity: 0;
    -webkit-animation: opacity-delay;
            animation: opacity-delay;
    -webkit-animation-delay: 2.5s;
            animation-delay: 2.5s;
    -webkit-animation-duration: 0.9s;
            animation-duration: 0.9s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    z-index: 100; /* navbar is set at index 99 but if you fullscreen the google map it needs to overlap it! */
}

.contact-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 2.5vmin;
    padding: 0 2vmin;
    color: grey;
    background-color: #090909;
    width: 42vmin;
    border-left: 2px solid cyan;
}

.contact-text i {
    justify-self: flex-end;
}

.contact-text a {
	color: darkgrey;
}

.colored {
	color: cyan
}

.copy-icon:hover {
	color: cyan;
	cursor: pointer;
}

.marker {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: -1.1vmin;
    left: -1.1vmin;
    width: 2.2vmin;
    height: 2.2vmin;
    background: transparent;
    border: 0.5vmin solid yellow;
	-webkit-animation: opacity-delay;
	        animation: opacity-delay;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-delay: 3s;
            animation-delay: 3s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    opacity: 0;
}

.marker p {
    font-size: 1.7vmin;
    padding: 0.15vmin;
    position: relative;
    left: 2vmin;
    background-color: #101010;
    color: white;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-wrap: nowrap;
    text-align: center;
}

